import { Helmet } from '@/components'
import { Box, Grid, useMediaQuery } from '@mui/material'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect } from 'react'
import { Footer, Header, SEO, useI18next } from '../../components'
import Title from '../../components/Title'

const downloadList = [
  { icon: 'icon', name: 'App Store' },
  { icon: 'google', name: 'Google play' },
  { icon: 'huawei', name: 'AppGallery' },
]

export default (props) => {
  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media(min-width: 800px)')

  useLayoutEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  const Movement = () => {
    return (
      <Grid
        container
        spacing={{ xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px' }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {pageLocale.content2.map((item, index) => (
          <Grid item xs={4} sm={4} md={4} key={index}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: ['24px'],
                boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.06)',
                '.tit': {
                  '--height': ['35px', '70px'],
                  height: 'var(--height)',
                  lineHeight: 'var(--height)',
                  bgcolor: '#095ef7',
                  color: '#fff',
                  fontSize: [18, 22],
                  textAlign: 'center',
                },
                '.desc': {
                  color: '#5b5c73',
                  fontSize: [14, 16],
                  p: ['15px', '20px'],
                  textAlign: 'justify',
                },
                '.bg-img1': {
                  objectFit: 'fill',
                },
              }}
            >
              {index === 0 && (
                <img
                  src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/service/air.png'}
                  alt='About iMile Delivery'
                  className='bg-img1'
                />
                // <StaticImage
                //   src={'../../../static/images/service/air.png'}
                //   alt='About iMile Delivery'
                //   objectFit='fill'
                //   loading='lazy'
                //   placeholder='blurred'
                //   className='bg-img1'
                // />
              )}
              {index === 1 && (
                <img
                  src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/service/sea.png'}
                  alt='About iMile Delivery'
                  className='bg-img1'
                />
                // <StaticImage
                //   src={'../../../static/images/service/sea.png'}
                //   alt='About iMile Delivery'
                //   objectFit='fill'
                //   loading='lazy'
                //   placeholder='blurred'
                //   className='bg-img1'
                // />
              )}
              {index === 2 && (
                <img
                  src={'https://imile-minio-cdn.imile.com/imile-home/offical-web/service/land.png'}
                  alt='About iMile Delivery'
                  className='bg-img1'
                />
                // <StaticImage
                //   src={'../../../static/images/service/land.png'}
                //   alt='About iMile Delivery'
                //   objectFit='fill'
                //   loading='lazy'
                //   placeholder='blurred'
                //   className='bg-img1'
                // />
              )}
              <div className='tit'>{item.split('&&')[0]}</div>
              <div className='desc'>{item.split('&&')[1]}</div>
            </Box>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      <SEO
        title={seoLocale.international.title}
        description={seoLocale.international.description}
        pageLocale={pageLocale}
      />
      <Helmet>
        <meta
          name='keywords'
          content='International logistics services, International shipping services, International delivery service, International courier services'
        />

        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='iMile Delivery' />
        <meta property='og:url' content='https://www.imile.com/service/international' />
        <meta
          property='og:image'
          content='https://www.imile.com/static/3944374dfe76657f0952b911941d434a/9b271/international-banner.webp'
        />
        <meta
          property='og:title'
          content='International Delivery and Cross-Border Logistics Solutions'
        />
        <meta
          property='og:description'
          content='iMile Cross Border Solutions bridges the gap between you and your customers internationally. iMile delivery is currently present in more than 11 countries across three markets'
        />
      </Helmet>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            width: 1,
            height: ['100vh', '100vh', '650px', '790px'],
          },
        }}
      >
        {isPc ? (
          //   <StaticImage
          //     src='../../../static/images/service/international-banner.png'
          //     alt='About iMile Delivery'
          //     objectFit='cover'
          //     loading='lazy'
          //     placeholder='blurred'
          //     className='bg-img'
          //   />
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/international-banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/international-banner_mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
          //   <StaticImage
          //     src='../../../static/images/service/international-banner_mobile.png'
          //     alt='About iMile Delivery'
          //     objectFit='cover'
          //     loading='lazy'
          //     placeholder='blurred'
          //     className='bg-img'
          //   />
        )}
        <Box
          sx={{
            position: 'absolute',
            top: ['75%', '25%', '32%'],
            transform: 'translate(0, -40%)',
            left: ['16px', '34px', '80px'],
            color: '#fff',
            '.tit': {
              fontSize: [24, 30, 35, 35, 40],
              fontWeight: 600,
              mb: '10px',
              whiteSpace: 'pre-line',
            },
            '.desc': {
              fontSize: [14, 18, 22, 22, 28],
              whiteSpace: 'pre-line',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* International Movement */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
        }}
      >
        <Title title={pageLocale.title1} component='h2' />
        <Box
          sx={{
            fontSize: ['14px', '20px', '24px'],
            mb: ['30px', '60px'],
          }}
        >
          {pageLocale.content1}
        </Box>
        <Movement />
      </Box>

      {/* From China To The World */}

      <Box
        sx={{
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
        }}
      >
        <Title title={pageLocale.title2} component='h2' />
        <Box
          sx={{
            px: 'var(--page-px)',
            fontSize: ['14px', '20px', '24px'],
            mb: ['30px', '60px'],
          }}
        >
          {pageLocale.content3}
        </Box>
        <Box
          sx={{
            px: [0, 0, 'var(--page-px)'],
          }}
        >
          <img
            src={
              'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/international1.png'
            }
            alt='About iMile Delivery'
            className='bg-img'
          />
          {/* <StaticImage
            src={'../../../static/images/service/international1.png'}
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          /> */}
        </Box>
      </Box>

      {/* Bridging The Gap */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
        }}
      >
        <Title title={pageLocale.title3} component='h2' />
        <Box
          sx={{
            fontSize: ['14px', '20px', '24px'],
            mb: ['30px', '60px'],
          }}
        >
          {pageLocale.content4}
        </Box>
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["International"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
